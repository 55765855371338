import React from "react";
import Slider from "../Components/Slider/Slider";
import img1 from "../resources/images/slider/slider7.jpg";
import img2 from "../resources/images/slider/slider5.jpg";
import img3 from "../resources/images/slider/slider6.jpg";
import img4 from "../resources/images/architecture.jpg";
import img5 from "../resources/images/renovation.jpg";
import img6 from "../resources/images/coordination.jpg";

export default class Home extends React.Component {
  render() {
    return (
      <div className="row">
        <Slider showArrows={true}>
          <div className="slider-element">
            <img src={img1} alt="Planung" />
            <p className="legend right-top-add-text">
              Von der ersten Idee bis zur Fertigstellung:{" "}
              <span className="highlight">
                Mit uns wird Ihr Bauvorhaben Realität.
              </span>
            </p>
          </div>
          <div className="slider-element">
            <img src={img2} alt="Elektrik" />
            <p className="legend right-bottom-add-text">
              Lückenlose Baustellen-Koordination:{" "}
              <span className="highlight">
                Wir machen Ihnen das Bauen leichter.{" "}
              </span>
            </p>
          </div>
          <div className="slider-element">
            <img src={img3} alt="Helme" />
            <p className="legend left-top-add-text">
              "Die Bausubstanz ist in Ordnung, aber …":{" "}
              <span className="highlight">
                Wir holen Ihr Objekt zurück ins Leben.
              </span>
            </p>
          </div>
        </Slider>
        <div className="container">
          <div className="row place-holder">
            <div className="col-12 text-center">
              <h4 className="secondary">
                GUT GEBAUT - DIE E.K. INVEST BAU GMBH
              </h4>
              <h1 style={{ margin: "5px 0px" }} className="primary extended">
                Bauen und Sanieren - Ihr zuverlässiger Baumeisterbetrieb
              </h1>
            </div>
            <div className="col-12 text-center">
              <p
                style={{
                  maxWidth: "800px",
                  display: "inline-block",
                  marginTop: "10px"
                }}
              >
                Bei E.K. Invest Bau, Ihrem langjährig erfahrenen
                Baumeisterbetrieb in Wien, sind Sie richtig - und herzlich
                willkommen.
              </p>
              <p
                style={{
                  maxWidth: "800px",
                  display: "inline-block",
                  marginTop: "10px"
                }}
              >
                Gegründet 2011 ist die E.K. Invest Bau GmbH über die Jahre
                gesund gewachsen. Unser multiprofessionelles Team ist in allen
                Fragen der Sanierung, für Neubauprojekte und für umfassende
                Dienstleistungen rund um die Planungs- und
                Baustellen-Koordination für Sie da. Mit persönlichem Service,
                mit der Expertise aus vielen Jahren und mit einem Maximum an
                Kundenorientierung.
              </p>
            </div>
          </div>
          <div className="row place-holder">
            <div className="col-12 text-center" />
            <div className="col-12 text-center" />
          </div>
          <div className="row justify-content-center place-holder">
            <div className="col-12 col-sm-6 col-md-4 text-center">
              <div className="card">
                <div className="card-head">
                  <img src={img4} alt="Architektur" />
                </div>
                <div className="card-body">
                  <h2>Neubauprojekte</h2>
                  <p>
                    Wir bauen, was Sie erschaffen wollen. Wir machen Visionen zu
                    zukunftssicheren Objekten. Ob Einfamilienhaus,
                    Mehrfamilienhaus oder Wohnanlage - unser Expertenteam
                    begleitet Ihr Neubauprojekt vom Einreichplan bis zur
                    Übergabe.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 text-center">
              <div className="card">
                <div className="card-head">
                  <img src={img5} alt="Renovierung" />
                </div>
                <div className="card-body">
                  <h2>Umbau & Sanierung</h2>
                  <p>
                    Als Spezialisten für Umbauten und Sanierung verstehen wir
                    uns darauf, Wertvolles zu erhalten und mit Neuem zu
                    verbinden. Ob Altbausanierung oder Dachausbau - wir beleben
                    in die Jahre gekommene Objekte mit zeitgemäßer Architektur.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 text-center">
              <div className="card">
                <div className="card-head">
                  <img src={img6} alt="Koordination" />
                </div>
                <div className="card-body">
                  <h2>Baustellenkoordination</h2>
                  <p>
                    Am Ort des Geschehens fühlen wir uns besonders wohl. Unser
                    Team entlastet Bauherren. Auf Ihrer Baustelle koordinieren
                    und überwachen wir sämtliche Tätigkeiten mit speziellem
                    Fokus auf sichere, qualitätsvolle und vorschriftsmäßige
                    Durchführung.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
