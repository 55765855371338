import React from 'react';


export default class Content extends React.Component {


    render() {
        let content = React.cloneElement(this.props.views[this.props.content], {});
        return (
            <div className="page-content">
                {content}
            </div>
        )
    }
}
