import React, {Component, Children} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import "./scss/main.scss";

export default class Carousel extends Component {
    state = {
        total: 0,
        current: 0,
    }

    componentDidMount() {
        const {children} = this.props;
        this.setState({total: Children.count(children)});
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    showNext = () => {
        const {total, current} = this.state;
        this.setState({
            current: current + 1 === total ? 0 : current + 1
        });
    };

    showPrevious = () => {
        const {total, current} = this.state;
        this.setState({
            current: current - 1 < 0 ? total - 1 : current - 1
        });
    };

    genPreviousButton = () => {
        return (<button className="previous-carousel" onClick={this.showPrevious}>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </button>)
    }

    genNextButton = () => {
        return(<button className="next-carousel" onClick={this.showNext}>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </button>)
    }

    render() {
        const {children} = this.props;

        return (
            <div className="carousel">
                {(this.state.total > 1) ? this.genPreviousButton(): ''}
                {(this.state.total > 1) ? this.genNextButton(): ''}
                <ReactCSSTransitionGroup
                    transitionName="example"
                    transitionEnterTimeout={1000}
                    transitionLeaveTimeout={1000}
                >
                    {Children.toArray(children)[this.state.current]}
                </ReactCSSTransitionGroup>
            </div>
        )
    }
}
