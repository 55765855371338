import React from 'react';
import "./scss/main.scss";

export default class Footer extends React.Component {

    render() {
        return (
            <div className="row footer place-holder">
                <div className="container">
                    <div className="row">

                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-6">
                            <ul className="footer-list-no-icon">
                                <li>E.K. Invest Bau ist Ihr verantwortungsbewusster Baumeisterbetrieb für Sanierungen, Umbaute, Neubauten - kurzum für jedes Bauvorhaben, das Sie perfekt umgesetzt wissen möchten. Gleich Beratungstermin vereinbaren!</li>
                                <li>ATU 67104135</li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6">
                            <ul className="footer-list">
                                <li><i className="fa fa-building-o"></i> <span>Maria-Kuhn-Gasse 6/2/B1, 1100 Wien</span></li>
                                <li><i className="fa fa-fax"></i> <span>+43 (0) 1 982 27 24</span></li>
                                <li><i className="fa fa-phone"></i> <span>+43 (0) 660 131 17 70</span></li>
                                <li><i className="fa fa-envelope"></i> <span>office@ekinvestbau.at</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
