import React from 'react';
import Home from './views/home'
import Projects from './views/projects'
import Impressum from './views/impressum'

const store = {
    navigation: {
        home: {
            title: 'Home',
            href: '#home',
            active: false,
        },
        projekte: {
            title: 'Projekte',
            href: '#projekte',
            active: false,
        },
        impressum: {
            title: 'Impressum',
            href: '#impressum',
            active: false,
        }
    },
    views:{
        home: <Home/>,
        projekte: <Projects/>,
        impressum: <Impressum/>
    }
};

export default store