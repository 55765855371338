import React from 'react';
import Menu from "../Menu/Menu";
import Content from "../Content/Content";
import Footer from "../Footer/Footer";
import "./scss/main.scss";

export default class Router extends React.Component {

    constructor(props) {
        super(props);
        let url = window.location.href.split('#');
        let view = (url[1]) ? url[1] : 'home';

        let navigation = {...this.props.store.navigation};
        navigation[view].active = true;

        this.state = {
            navigation: navigation,
            content: view,
            views: this.props.store.views,
        }
    }

    changeRoute(link) {
        let helper = {...this.state};
        for (let linkNew in helper.navigation) {
            helper.navigation[linkNew].active = false;
        }
        helper.navigation[link].active = true;
        helper.content = link;
        this.setState(helper);
    }

    render() {
        return (
            <div className="container-fluid">
                <Menu store={this.state} changeRoute={this.changeRoute.bind(this)} settings={{style: 'inline'}}/>
                <Content views={this.state.views} content={this.state.content}/>
                <Footer/>
            </div>
        )
    }
}
