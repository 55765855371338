import React from "react";
import logo from "../../resources/images/logo.png";
import "./scss/main.scss";

export default class Menu extends React.Component {
  //settings
  //align: inline sets navigation inline

  renderLinks = () => {
    const navigation = this.props.store.navigation;
    return Object.keys(navigation).map(key => {
      let link = navigation[key];
      return (
        <li key={"menu-link-" + key}>
          <a
            href={link.href}
            className={link.active ? "active" : ""}
            onClick={() => {
              this.props.changeRoute(key);
            }}
          >
            {link.title}
          </a>
        </li>
      );
    });
  };

  render() {
    const settings = this.props.settings;

    let navClass = [];
    if (settings.style) navClass.push(settings.style);
    return (
      <div className="menu-holder row">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 logo-alignment">
              <img className="logo" src={logo} alt="logo" />
            </div>
            <div className="col-12 col-sm-6 menu-head-alignment pd-t-25">
              <div className="icon-text-sm">
                <i className="fa fa-fax" />
                <div className="icon-text">
                  <h4>Tel./Fax.</h4>
                  <p>+43 (0) 1 982 27 24</p>
                </div>
              </div>
              <div className="icon-text-sm">
                <i className="fa fa-envelope" />
                <div className="icon-text">
                  <h4>E-Mail</h4>
                  <p>office@ekinvestbau.at</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <nav className={navClass.join(" ")}>
                <ul>{this.renderLinks()}</ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
