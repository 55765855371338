import React from "react";
import Carousel from "../Components/Carousel/Carousel";
import img1 from "../resources/images/project7/IMG_0741.jpg";
import img2 from "../resources/images/project7/IMG_0755.jpg";
import img3 from "../resources/images/project8/IMG_0819.jpg";
import img4 from "../resources/images/project8/IMG_0820.jpg";
import img15 from "../resources/images/project8/IMG_0822.jpg";
import img13 from "../resources/images/project9/IMG_0801.jpg";
import img14 from "../resources/images/project9/IMG_0802.jpg";
import img5 from "../resources/images/project5/IMG_0742.jpg";
import img6 from "../resources/images/project5/IMG_0744.jpg";
import img7 from "../resources/images/project6/IMG_0807.jpg";
import img8 from "../resources/images/project6/IMG_0808.jpg";
import img9 from "../resources/images/project6/IMG_0809.jpg";
import img10 from "../resources/images/project6/IMG_0811.jpg";
import img11 from "../resources/images/project6/IMG_0812.jpg";
import img12 from "../resources/images/project6/IMG_0813.jpg";
import img16 from "../resources/images/project3/IMG_0753.jpg";
import img17 from "../resources/images/project3/IMG_0773.jpg";
import img18 from "../resources/images/project4/IMG_0834.jpg";
import img19 from "../resources/images/project4/IMG_0835.jpg";
import img20 from "../resources/images/project1/IMG_0803.jpg";
import img21 from "../resources/images/project1/IMG_0800.jpg";
import img22 from "../resources/images/project1/IMG_0799.jpg";
import img23 from "../resources/images/project1/IMG_0798.jpg";
import img24 from "../resources/images/project1/IMG_0775.jpg";
import img25 from "../resources/images/project2/IMG_0774.jpg";

export default class Projects extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="container">
          <div className="row pd-t-45">
            <div className="col-12 text-left">
              <h4 className="secondary">PROJEKTE</h4>
              <h1 style={{ margin: "5px 0px" }} className="primary extended">
                Referenzen & Projekte: Möchten Sie mal sehen?
              </h1>
            </div>
            <div className="col-12 text-left">
              <p
                style={{
                  maxWidth: "800px",
                  display: "inline-block",
                  marginTop: "10px"
                }}
              >
                Bei E.K. Invest Bau haben wir viel zu geben und nichts zu
                verbergen. Begleiten Sie uns hier durch einen Auszug aus unseren
                Referenzprojekten in Bildern und Fakten!
              </p>
            </div>
          </div>
          <div className="row pd-t-45">
            <div className="col-12 col-sm-12 col-md-8 text-left">
              <h4 className="secondary">BADEZIMMER</h4>
            </div>
          </div>
          <div className="row pd-t-5">
            <div className="col-12 col-sm-6 col-md-4">
              <div className="card">
                <div className="card-head">
                  <Carousel showArrows={true}>
                    <div className="carousel-element">
                      <img src={img1} alt="Badezimmer" />
                    </div>
                    <div className="carousel-element">
                      <img src={img2} alt="Badezimmer" />
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="card">
                <div className="card-head">
                  <Carousel showArrows={true}>
                    <div className="carousel-element">
                      <img src={img3} alt="Badezimmer" />
                    </div>
                    <div className="carousel-element">
                      <img src={img4} alt="Badezimmer" />
                    </div>
                    <div className="carousel-element">
                      <img src={img15} alt="Badezimmer" />
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="card">
                <div className="card-head">
                  <Carousel showArrows={true}>
                    <div className="carousel-element">
                      <img src={img13} alt="Badezimmer" />
                    </div>
                    <div className="carousel-element">
                      <img src={img14} alt="Badezimmer" />
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <div className="row pd-t-45">
            <div className="col-12 col-sm-12 col-md-8 text-left">
              <h4 className="secondary">ALTBAUSANIERUNG</h4>
            </div>
          </div>
          <div className="row pd-t-5">
            <div className="col-12 col-sm-6 col-md-4">
              <div className="card">
                <div className="card-head">
                  <Carousel showArrows={true}>
                    <div className="carousel-element">
                      <img src={img5} alt="Altbau" />
                    </div>
                    <div className="carousel-element">
                      <img src={img6} alt="Altbau" />
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="card">
                <div className="card-head">
                  <Carousel showArrows={true}>
                    <div className="carousel-element">
                      <img src={img7} alt="Altbau" />
                    </div>
                    <div className="carousel-element">
                      <img src={img8} alt="Altbau" />
                    </div>
                    <div className="carousel-element">
                      <img src={img9} alt="Altbau" />
                    </div>
                    <div className="carousel-element">
                      <img src={img10} alt="Altbau" />
                    </div>
                    <div className="carousel-element">
                      <img src={img11} alt="Altbau" />
                    </div>
                    <div className="carousel-element">
                      <img src={img12} alt="Altbau" />
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <div className="row pd-t-45 justify-content-end">
            <div className="col-12 col-sm-12 col-md-8 text-right">
              <h4 className="secondary">EINFAMILIENHÄUSER</h4>
            </div>
          </div>
          <div className="row pd-t-5 justify-content-end">
            <div className="col-12 col-sm-6 col-md-4">
              <div className="card">
                <div className="card-head">
                  <Carousel showArrows={true}>
                    <div className="carousel-element">
                      <img src={img16} alt="Einfamilienhaus" />
                    </div>
                    <div className="carousel-element">
                      <img src={img17} alt="Einfamilienhaus" />
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="card">
                <div className="card-head">
                  <Carousel showArrows={true}>
                    <div className="carousel-element">
                      <img src={img18} alt="Einfamilienhaus" />
                    </div>
                    <div className="carousel-element">
                      <img src={img19} alt="Einfamilienhaus" />
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <div className="row pd-t-45">
            <div className="col-12 col-sm-12 col-md-8">
              <h4 className="secondary">MEHRFAMILIENHÄUSER</h4>
            </div>
          </div>
          <div className="row pd-t-5">
            <div className="col-12 col-sm-6 col-md-4">
              <div className="card">
                <div className="card-head">
                  <Carousel showArrows={true}>
                    <div className="carousel-element">
                      <img src={img20} alt="Mehrfamilienhaus" />
                    </div>
                    <div className="carousel-element">
                      <img src={img21} alt="Mehrfamilienhaus" />
                    </div>
                    <div className="carousel-element">
                      <img src={img22} alt="Mehrfamilienhaus" />
                    </div>
                    <div className="carousel-element">
                      <img src={img23} alt="Mehrfamilienhaus" />
                    </div>
                    <div className="carousel-element">
                      <img src={img24} alt="Mehrfamilienhaus" />
                    </div>
                    <div className="carousel-element">
                      <img src={img25} alt="Mehrfamilienhaus" />
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
