import React, {Component, Children} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import "./scss/main.scss";

export default class Slider extends Component {
    state = {
        total: 0,
        current: 0,
    }

    componentDidMount() {
        const {children} = this.props;
        this.setState({total: Children.count(children)});
        this.interval = setInterval(this.showNext, 7000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    showNext = () => {
        const {total, current} = this.state;
        this.setState({
            current: current + 1 === total ? 0 : current + 1
        });
    };

    render() {
        const {children} = this.props;
        return (
            <div ref="slider" className="slideshow">
                <ReactCSSTransitionGroup
                    transitionName="example"
                    transitionEnterTimeout={1000}
                    transitionLeaveTimeout={1000}
                >
                    {Children.toArray(children)[this.state.current]}
                </ReactCSSTransitionGroup>
            </div>
        )
    }
}
