import React from "react";

import Router from "./Components/Router/Router";
import store from "./store";

import "./scss/font-awesome/scss/font-awesome.scss";
import "./scss/main.scss";
import "bootstrap-4-grid";

function App() {
  return <Router store={store} />;
}

export default App;
